import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 128 128"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", {
        fill: "#E9523D",
        d: "M0 0h128v128H0z"
      }),
      _createElementVNode("path", {
        fill: "#FFF",
        "fill-rule": "nonzero",
        d: "M92 74.611c-1.421.46-3.507.512-5.744.156-2.4-.382-4.85-1.188-7.256-2.374 4.292-.685 7.621-.475 10.469.633.675.263 1.783.964 2.531 1.585m-25-4.373q-.257.067-.509.138c-1.135.31-2.24.612-3.303.882l-1.435.366C58.866 72.358 55.914 73.108 53 74c1.108-2.684 2.137-5.397 3.145-8.05.746-1.963 1.508-3.97 2.296-5.95q.6.994 1.251 1.992c1.977 3.026 4.463 5.824 7.308 8.246m-7.032-29.875c.198 3.34-.552 6.554-1.653 9.637-1.355-3.812-1.987-8.021-.292-11.42.434-.871.79-1.337 1.021-1.58.357.53.826 1.713.924 3.363M45 83a54 54 0 0 1-2.37 3.513C40.677 89.163 37.486 92 35.846 92c-.16 0-.356-.023-.641-.295-.184-.174-.214-.298-.205-.467.055-.974 1.488-2.71 3.563-4.318C40.448 85.46 42.577 84.163 45 83m51.992-8.49c-.24-3.415-6.044-5.606-6.102-5.626-2.244-.788-4.682-1.171-7.452-1.171-2.966 0-6.163.425-10.27 1.375-3.653-2.565-6.81-5.776-9.168-9.332a57 57 0 0 1-2.794-4.67c1.992-4.718 3.786-9.79 3.46-15.471C64.404 35.06 62.33 32 59.51 32c-1.935 0-3.601 1.419-4.956 4.222-2.416 4.995-1.781 11.386 1.887 19.012-1.321 3.073-2.549 6.259-3.737 9.344-1.478 3.835-3.001 7.792-4.718 11.556-4.815 1.887-8.77 4.174-12.066 6.983-2.16 1.837-4.763 4.645-4.912 7.577-.072 1.38.405 2.646 1.377 3.66C33.415 95.432 34.712 96 36.138 96c4.708 0 9.24-6.407 10.098-7.691 1.73-2.583 3.35-5.463 4.936-8.786 3.997-1.43 8.256-2.497 12.384-3.53l1.478-.372c1.11-.28 2.266-.589 3.45-.91 1.254-.336 2.544-.683 3.855-1.014 4.238 2.67 8.796 4.41 13.242 5.05 3.744.539 7.07.226 9.32-.936 2.026-1.045 2.139-2.656 2.091-3.301"
      })
    ], -1)
  ])))
}
export default { render: render }