import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 128 128"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", {
        fill: "#5984B8",
        d: "M0 0h128v128H0z"
      }),
      _createElementVNode("path", {
        fill: "#FFF",
        "fill-rule": "nonzero",
        d: "M70.611 30H46.167C41.104 30 37 34.09 37 39.136v48.728C37 92.91 41.104 97 46.167 97h36.666C87.896 97 92 92.91 92 87.864V51.318a3.04 3.04 0 0 0-.895-2.153L72.772 30.892a3.06 3.06 0 0 0-2.16-.892m-.856 6L86 52.546v35.398C86 89.632 84.657 91 83 91H47c-1.657 0-3-1.368-3-3.056V39.056C44 37.368 45.343 36 47 36z"
      }),
      _createElementVNode("path", {
        fill: "#FFF",
        "fill-rule": "nonzero",
        d: "M71 30a3 3 0 0 1 2.98 2.65L74 33v15h15a3 3 0 0 1 2.98 2.65L92 51a3 3 0 0 1-2.65 2.98L89 54H71a3 3 0 0 1-2.98-2.65L68 51V33a3 3 0 0 1 3-3M76.9 64c1.712 0 3.1 1.567 3.1 3.5 0 1.795-1.197 3.274-2.738 3.476L76.9 71H52.1c-1.712 0-3.1-1.567-3.1-3.5 0-1.795 1.197-3.274 2.738-3.476L52.1 64zM76.9 76c1.712 0 3.1 1.567 3.1 3.5 0 1.795-1.197 3.274-2.738 3.476L76.9 83H52.1c-1.712 0-3.1-1.567-3.1-3.5 0-1.795 1.197-3.274 2.738-3.476L52.1 76zM58 53c1.657 0 3 1.12 3 2.5 0 1.282-1.158 2.339-2.65 2.483L58 58h-6c-1.657 0-3-1.12-3-2.5 0-1.282 1.158-2.339 2.65-2.483L52 53z"
      })
    ], -1)
  ])))
}
export default { render: render }