<template>
  <a
    :href="href"
    target="_blank"
    :class="[
      'document-link',
      !noMargin && 'document-link-hasMargin',
      `document-link-l-${layout}`,
    ]"
    download
  >
    <component :is="type" class="image" />
    <span class="label"><slot /></span>
  </a>
</template>

<script>
  import doc from "./icons/doc.svg?component"
  import ifra from "./icons/ifra.svg?component"
  import pdf from "./icons/pdf.svg?component"
  import sheet from "./icons/sheet.svg?component"

  export default {
    components: {
      ifra,
      doc,
      pdf,
      sheet,
    },
    props: {
      noMargin: {
        type: Boolean,
        default: false,
      },
      image: {
        type: String,
        default: null,
      },
      layout: {
        type: String,
        default: "default",
        validator(value) {
          return ["default", "order"].includes(value)
        },
      },
      href: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        default: "custom",
        validator(value) {
          return ["custom", "ifra", "sheet", "doc", "pdf"].includes(value)
        },
      },
    },
    data() {
      return {
        doc,
        ifra,
        pdf,
        sheet,
      }
    },
    computed: {
      imagePath() {
        return this.type || this.image
      },
    },
  }
</script>

<style lang="scss" scoped>
  .document-link {
    max-width: 280px;
    cursor: pointer;
    background: white;
    font-family: $font-family-default;
    display: inline-grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    color: inherit;
    align-items: center;
    text-decoration: none;
    border-radius: $border-radius;
    overflow: hidden;
    box-shadow: $shadow;
    transition: box-shadow 120ms ease-out;

    &:hover {
      box-shadow: $hover-shadow;
    }

    &.document-link-hasMargin {
      margin-bottom: $base-spacing * 4;
    }

    &-l-order {
      @apply text-sm leading-normal;

      .image {
        height: 100%;
        min-height: $base-spacing * 8;
      }

      .label {
        padding: 0 $base-spacing * 4;
      }
    }
  }

  .image {
    width: $base-spacing * 12;
    aspect-ratio: 1 / 1;
  }

  .label {
    font-weight: bold;
    padding: $base-spacing * 2 $base-spacing * 4;
  }
</style>
