import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 128 128"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#71B734\" d=\"M0 0h128v128H0z\"></path><g fill=\"#FFF\" fill-rule=\"nonzero\"><path d=\"M67.556 30h-21.39C41.105 30 37 34.152 37 39.273v49.454C37 93.848 41.104 98 46.167 98h36.666C87.896 98 92 93.848 92 88.727v-34a3.1 3.1 0 0 0-.895-2.185L69.716 30.905a3.04 3.04 0 0 0-2.16-.905m-1.26 7L86 56.601v32.343A3.064 3.064 0 0 1 82.929 92H46.07A3.064 3.064 0 0 1 43 88.944V40.056A3.064 3.064 0 0 1 46.071 37z\"></path><path d=\"M68 30c1.539 0 2.807 1.201 2.98 2.748l.02.363v18.667h18c1.539 0 2.807 1.2 2.98 2.748l.02.363c0 1.595-1.158 2.91-2.65 3.09L89 58H68c-1.539 0-2.807-1.201-2.98-2.748l-.02-.363V33.11C65 31.393 66.343 30 68 30\"></path></g></g>", 1)
  ])))
}
export default { render: render }